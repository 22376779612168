.side-bars {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.game-flex-div {
  display: flex;
  justify-content: space-between;
}

.logged-out-div {
  height: 1301px;
  background: #c9c9c9;
  text-align: center;
}

@media screen and (min-width: 1212px) {
  .logged-out-div {
    width: 1212px;
  }

  .side-bars {
    max-width: 1212px;
  }

  .game-flex-div {
    max-width: 1212px;
  }

  .max-width {
    min-width: 1024px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1211px) {
  .logged-out-div {
    width: 100%;
  }

  .side-bars {
    width: 100%;
  }

  .game-flex-div {
    width: 100%;
  }

  .max-width {
    min-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .logged-out-div {
    width: 100%;
  }

  .side-bars {
    width: 100%;
  }

  .game-flex-div {
    width: 100%;
  }

  .max-width {
    min-width: 100%;
  }
}
@media screen and (min-width: 1212px) {

}

@media screen and (max-width: 1211px) {
  .wrapper {
    position: absolute;
    z-index: 1000;
    margin: 0 0 auto auto;
    min-width: 100%;
  }
}

.hidden {
  background: black;
  display: none;
}

.wrapper {
  background: #D8D8D8;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
}

.innerDiv {
  padding: 20px 20px 20px 16px;
  box-sizing: border-box;
}

.headerContent {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.headerContent div {
  margin-top: 4px;
}

.iconFlex {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.iconFlex svg {
  margin-right: 12px;
}

.popularDiv {
  margin-top: 58px;
}

.spanMargin {
  margin-bottom: 17px;
  display: block;
}

.listFlex {
  display: flex;
  flex-direction: column;
  border-left: 2px solid rgba(0,0,0,0.5);
  padding-left: 6px;
  margin-bottom: 10px;
}

.smallSpan {
  color: rgba(0,0,0,0.5);
  font-size: 12px;
}

.sportsMargin {
  margin-bottom: 14px;
}

.sportsDiv {
  display: flex;
  flex-direction: column;
  margin-top: 51px;
}

.gray {
  color: rgba(0,0,0,0.5);
  margin-bottom: 6px;
}

.btn {
  background: #000;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: none;
  padding: 14px 24px 12px 24px;
  border-radius: 35px;
  margin-top: 40px;
}

/* Firefox */
@-moz-document url-prefix() { 
  .innerDiv {
    padding: 20px 18px 9px 12px;
  }
}
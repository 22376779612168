.flexCenter {
    display: flex;
    align-items: center;
}

.select {
    background: #f3f3f3;
    padding: 12px 21px;
    border: none;
    border-radius: 5px;
    display: block;
    font-size: 16px;
    font-weight: bold;
}
.wrapper {
  background: #D8D8D8;
  width: 302px;
  position: fixed;
  right: -2000px;
  transition: .25s ease-in-out;
  height: 100%;
}

.bold {
  font-weight: bold;
}

.moneyDiv {
  display: flex;
  margin-top: 12px;
  align-items: center;
}

.moneySpan {
  margin-left: 4px;
  font-size: 14px;
  opacity: .5;
}

.topInfoDiv {
  border-bottom: 1px solid rgba(0, 0, 0, .5);
  margin: 20px 20px 20px 32px;
  padding-bottom: 20px;
}

.bottomInfoDiv {
  padding: 20px 20px 20px 32px;
}

.bottomInfoDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}

.transactionDiv {
  margin-bottom: 26px;
}

.headerSpan {
  font-size: 14px;
}

.logoutBtn {
  background: #000;
  color: #fff;
  border: none;
  padding: 12px 20px 12px 8px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  justify-content: center;
  cursor: pointer;
}

.logoutBtn:hover {
  text-decoration: underline;
}

.headerContent {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-top: 12px;
}

.headerContent div {
  margin-top: 4px;
}

.iconFlex {
  display: flex;
  align-items: flex-end;
  margin: 14px 0;
  cursor: pointer;
}

.iconFlex:hover {
  text-decoration: underline;
}

.iconFlex svg {
  margin-right: 8px;
}

.myGameCheck {
  margin-top: 20px;
}

.slideIn {
  position: absolute;
  margin: 0 0 auto auto;
  right: 0;
}

.nameFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: #C4C4C4;
  border-radius: 35px;
  margin-right: 20px;
}

.stopSpan {
  font-size: 14px;
  opacity: .5;
}

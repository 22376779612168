
@media screen and (min-width: 1212px) {
  .topBar {
    width: 1212px;
  }

  .profileFont {
    font-size: 18px;
  }


  .closeMenuButton {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 1211px) {
  .topBar {
    width: 100%;
  }


  .profileFont {
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  .topBar {
    width: 100%;
  }

  .menuFont {
    display: none;
  }

  .profileFont {
    font-size:  12px;
  }
}


/* Special logo directive */
@media screen and (max-width: 1000px) {
  .title {
    display: none;
  }
}

.topBar {
  background: #C4C4C4;
  display: flex;
  padding: 15px 18px 15px 18px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.title {
  margin: 0;
  font-size: 38px;
  font-family: 'Helvetica', sans-serif;
  margin-right: -250px;
}

.menuFont, .profileFont {
  font-weight: bold;
}

.menuFont {
  margin-left: 16px;
}

.profileDiv, .menuDiv {
  align-items: center;
  min-width: 30px;
  display: flex;
}

.profileDiv {
  cursor: pointer;
}

.profileFont {
  padding: 0 16px;
}

.overlay {
  position: absolute;
  height: 1790px;
  width: 100vw;
  background: rgba(0, 0, 0, .6);
}

.flexCenter {
  display: flex;
  align-items: center;
}

.userIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: #D8D8D8;
  border-radius: 35px;
  margin-right: 20px;
}

.balanceWrap {
  background: #D8D8D8;
  margin-right: 20px;
  border-radius: 35px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  background: #f3f3f3;
  padding: 12px 21px;
  border: none;
  border-radius: 5px;
  display: block;
  font-size: 16px;
  font-weight: bold;
}

@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&family=Reggae+One&display=swap);
body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.side-bars {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.game-flex-div {
  display: flex;
  justify-content: space-between;
}

.logged-out-div {
  height: 1301px;
  background: #c9c9c9;
  text-align: center;
}

@media screen and (min-width: 1212px) {
  .logged-out-div {
    width: 1212px;
  }

  .side-bars {
    max-width: 1212px;
  }

  .game-flex-div {
    max-width: 1212px;
  }

  .max-width {
    min-width: 1024px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1211px) {
  .logged-out-div {
    width: 100%;
  }

  .side-bars {
    width: 100%;
  }

  .game-flex-div {
    width: 100%;
  }

  .max-width {
    min-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .logged-out-div {
    width: 100%;
  }

  .side-bars {
    width: 100%;
  }

  .game-flex-div {
    width: 100%;
  }

  .max-width {
    min-width: 100%;
  }
}
.LoginButton_btnFlex__2FJcS {
  display: flex;
  align-items: center;
}


@media screen and (max-width: 640px){
  .LoginButton_loginBtn__3o5vx, .LoginButton_signUpBtn__3ZVNc {
    font-size: 12px;
  }
}
.LoginButton_loginBtn__3o5vx, .LoginButton_signUpBtn__3ZVNc {
  cursor: pointer;
}

.LoginButton_signUpBtn__3ZVNc {
  background: #D8D8D8;
  text-align: center;
  padding: 13px 19px;
  border-radius: 35px;
  margin-right: 20px;
}
.RightSidebar_wrapper__3RQCj {
  background: #D8D8D8;
  width: 302px;
  position: fixed;
  right: -2000px;
  transition: .25s ease-in-out;
  height: 100%;
}

.RightSidebar_bold__2EseC {
  font-weight: bold;
}

.RightSidebar_moneyDiv__2J0me {
  display: flex;
  margin-top: 12px;
  align-items: center;
}

.RightSidebar_moneySpan__3NPBU {
  margin-left: 4px;
  font-size: 14px;
  opacity: .5;
}

.RightSidebar_topInfoDiv__1rI6- {
  border-bottom: 1px solid rgba(0, 0, 0, .5);
  margin: 20px 20px 20px 32px;
  padding-bottom: 20px;
}

.RightSidebar_bottomInfoDiv__12ufp {
  padding: 20px 20px 20px 32px;
}

.RightSidebar_bottomInfoDiv__12ufp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}

.RightSidebar_transactionDiv__1BY62 {
  margin-bottom: 26px;
}

.RightSidebar_headerSpan__1zZjd {
  font-size: 14px;
}

.RightSidebar_logoutBtn__2A_w8 {
  background: #000;
  color: #fff;
  border: none;
  padding: 12px 20px 12px 8px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  justify-content: center;
  cursor: pointer;
}

.RightSidebar_logoutBtn__2A_w8:hover {
  text-decoration: underline;
}

.RightSidebar_headerContent__17rc3 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-top: 12px;
}

.RightSidebar_headerContent__17rc3 div {
  margin-top: 4px;
}

.RightSidebar_iconFlex__1M9uE {
  display: flex;
  align-items: flex-end;
  margin: 14px 0;
  cursor: pointer;
}

.RightSidebar_iconFlex__1M9uE:hover {
  text-decoration: underline;
}

.RightSidebar_iconFlex__1M9uE svg {
  margin-right: 8px;
}

.RightSidebar_myGameCheck__3iSic {
  margin-top: 20px;
}

.RightSidebar_slideIn__Apj7r {
  position: absolute;
  margin: 0 0 auto auto;
  right: 0;
}

.RightSidebar_nameFlex__2eTkp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RightSidebar_userIconWrap__FczEF {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: #C4C4C4;
  border-radius: 35px;
  margin-right: 20px;
}

.RightSidebar_stopSpan__2X7Kf {
  font-size: 14px;
  opacity: .5;
}

.LangSelector_flexCenter__YBlat {
    display: flex;
    align-items: center;
}

.LangSelector_select__JN5BF {
    background: #f3f3f3;
    padding: 12px 21px;
    border: none;
    border-radius: 5px;
    display: block;
    font-size: 16px;
    font-weight: bold;
}

@media screen and (min-width: 1212px) {
  .TopBar_topBar__1Gx4_ {
    width: 1212px;
  }

  .TopBar_profileFont__2Y0fp {
    font-size: 18px;
  }


  .TopBar_closeMenuButton__1T0XS {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 1211px) {
  .TopBar_topBar__1Gx4_ {
    width: 100%;
  }


  .TopBar_profileFont__2Y0fp {
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  .TopBar_topBar__1Gx4_ {
    width: 100%;
  }

  .TopBar_menuFont__212Oa {
    display: none;
  }

  .TopBar_profileFont__2Y0fp {
    font-size:  12px;
  }
}


/* Special logo directive */
@media screen and (max-width: 1000px) {
  .TopBar_title__2IlOa {
    display: none;
  }
}

.TopBar_topBar__1Gx4_ {
  background: #C4C4C4;
  display: flex;
  padding: 15px 18px 15px 18px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.TopBar_title__2IlOa {
  margin: 0;
  font-size: 38px;
  font-family: 'Helvetica', sans-serif;
  margin-right: -250px;
}

.TopBar_menuFont__212Oa, .TopBar_profileFont__2Y0fp {
  font-weight: bold;
}

.TopBar_menuFont__212Oa {
  margin-left: 16px;
}

.TopBar_profileDiv__2J29t, .TopBar_menuDiv__3G3Mh {
  align-items: center;
  min-width: 30px;
  display: flex;
}

.TopBar_profileDiv__2J29t {
  cursor: pointer;
}

.TopBar_profileFont__2Y0fp {
  padding: 0 16px;
}

.TopBar_overlay__3Hiwv {
  position: absolute;
  height: 1790px;
  width: 100vw;
  background: rgba(0, 0, 0, .6);
}

.TopBar_flexCenter__3IXEO {
  display: flex;
  align-items: center;
}

.TopBar_userIconWrap__1ogUS {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: #D8D8D8;
  border-radius: 35px;
  margin-right: 20px;
}

.TopBar_balanceWrap__3wGzN {
  background: #D8D8D8;
  margin-right: 20px;
  border-radius: 35px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TopBar_select__1vGM0 {
  background: #f3f3f3;
  padding: 12px 21px;
  border: none;
  border-radius: 5px;
  display: block;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (min-width: 1212px) {

}

@media screen and (max-width: 1211px) {
  .LeftSidebar_wrapper__1wClp {
    position: absolute;
    z-index: 1000;
    margin: 0 0 auto auto;
    min-width: 100%;
  }
}

.LeftSidebar_hidden__10hZe {
  background: black;
  display: none;
}

.LeftSidebar_wrapper__1wClp {
  background: #D8D8D8;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
}

.LeftSidebar_innerDiv__2DDYj {
  padding: 20px 20px 20px 16px;
  box-sizing: border-box;
}

.LeftSidebar_headerContent__3wCv4 {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.LeftSidebar_headerContent__3wCv4 div {
  margin-top: 4px;
}

.LeftSidebar_iconFlex__-HDLp {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.LeftSidebar_iconFlex__-HDLp svg {
  margin-right: 12px;
}

.LeftSidebar_popularDiv__2r69o {
  margin-top: 58px;
}

.LeftSidebar_spanMargin__37pe5 {
  margin-bottom: 17px;
  display: block;
}

.LeftSidebar_listFlex__3_AeG {
  display: flex;
  flex-direction: column;
  border-left: 2px solid rgba(0,0,0,0.5);
  padding-left: 6px;
  margin-bottom: 10px;
}

.LeftSidebar_smallSpan__eR5qV {
  color: rgba(0,0,0,0.5);
  font-size: 12px;
}

.LeftSidebar_sportsMargin__2fIDc {
  margin-bottom: 14px;
}

.LeftSidebar_sportsDiv__39mBk {
  display: flex;
  flex-direction: column;
  margin-top: 51px;
}

.LeftSidebar_gray__MYsAy {
  color: rgba(0,0,0,0.5);
  margin-bottom: 6px;
}

.LeftSidebar_btn__1x9l_ {
  background: #000;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: none;
  padding: 14px 24px 12px 24px;
  border-radius: 35px;
  margin-top: 40px;
}

/* Firefox */
@-moz-document url-prefix() { 
  .LeftSidebar_innerDiv__2DDYj {
    padding: 20px 18px 9px 12px;
  }
}
@media screen and (min-width: 1212px) {
  .Footer_footer__2RgBb {
    width: 1212px;
  }

  .Footer_helpDiv__2yRfR {
    width: 254px;
  }

  .Footer_link__4Ibtr {
    margin-right: 30px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1211px) {
  .Footer_footer__2RgBb {
    width: 100%;
  }

  .Footer_helpDiv__2yRfR {
    width: 254px;
  }

  .Footer_link__4Ibtr {
    margin-right: 30px;
  }
}
@media screen and (max-width: 640px) {
  .Footer_footer__2RgBb {
    flex-direction: column;
  }

  .Footer_helpDiv__2yRfR {
    margin-top: 36px;
    width: 60%;
  }

  .Footer_infoFlex__1qirK, .Footer_disclaimerFlex__2GSTX {
    flex-direction: column;
  }

  .Footer_link__4Ibtr {
    margin-top: 18px;
    text-align: center;
    width: 100%;
  }
}

.Footer_mobileLangSelectWrapper__2kBxW {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  width: 100%;  
  justify-content: flex-end;
}

.Footer_footer__2RgBb {
  display: flex;
  justify-content: space-between;
  background: #C4C4C4;
  padding: 37px 43px;
  box-sizing: border-box;
}

.Footer_gray__1ctrV {
  color: rgba(0, 0, 0, .5);
  font-size: 14px;
}

.Footer_downloadDiv__2SelO {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}

.Footer_iconFlex__2_Zr_ {
  align-items: center;
}

.Footer_infoFlex__1qirK, .Footer_disclaimerFlex__2GSTX {
  display: flex;
}

.Footer_disclaimerFlex__2GSTX {
  font-size: 12px;
  margin-top: 37px;
}

.Footer_helpDiv__2yRfR {
  display: flex;
  flex-direction: column;
}

.Footer_smallText__3xgeS {
  font-size: 14px;
  color: rgba(0, 0, 0, .5);
  margin-top: 12px;
}

.Footer_underline__1b2uF {
  text-decoration: underline;
}

.Footer_spacer__EtScr {
  margin: 15px 0 50px 0;
}

.btnFlex {
  display: flex;
  align-items: center;
}


@media screen and (max-width: 640px){
  .loginBtn, .signUpBtn {
    font-size: 12px;
  }
}
.loginBtn, .signUpBtn {
  cursor: pointer;
}

.signUpBtn {
  background: #D8D8D8;
  text-align: center;
  padding: 13px 19px;
  border-radius: 35px;
  margin-right: 20px;
}
@media screen and (min-width: 1212px) {
  .footer {
    width: 1212px;
  }

  .helpDiv {
    width: 254px;
  }

  .link {
    margin-right: 30px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1211px) {
  .footer {
    width: 100%;
  }

  .helpDiv {
    width: 254px;
  }

  .link {
    margin-right: 30px;
  }
}
@media screen and (max-width: 640px) {
  .footer {
    flex-direction: column;
  }

  .helpDiv {
    margin-top: 36px;
    width: 60%;
  }

  .infoFlex, .disclaimerFlex {
    flex-direction: column;
  }

  .link {
    margin-top: 18px;
    text-align: center;
    width: 100%;
  }
}

.mobileLangSelectWrapper {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  width: 100%;  
  justify-content: flex-end;
}

.footer {
  display: flex;
  justify-content: space-between;
  background: #C4C4C4;
  padding: 37px 43px;
  box-sizing: border-box;
}

.gray {
  color: rgba(0, 0, 0, .5);
  font-size: 14px;
}

.downloadDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}

.iconFlex {
  align-items: center;
}

.infoFlex, .disclaimerFlex {
  display: flex;
}

.disclaimerFlex {
  font-size: 12px;
  margin-top: 37px;
}

.helpDiv {
  display: flex;
  flex-direction: column;
}

.smallText {
  font-size: 14px;
  color: rgba(0, 0, 0, .5);
  margin-top: 12px;
}

.underline {
  text-decoration: underline;
}

.spacer {
  margin: 15px 0 50px 0;
}